import React from 'react'
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const AboutUsPage = () => {
  return (
    <div>
    <Navbar />
    <div className='w-screen mb-40 mt-40 h-full'>
    <h1 className='text-3xl mt-20 mb-10 font-bold text-center text-white'>За нас</h1>
    <h3 className='text-sm lg:text-md lg:text-xl px-5 lg:px-40 lg:my-20 font-semibold text-center text-white'>Радваме се за вашето посещение и вашия интерес към нашите оферти! Предлагаме Ви широка гама от автомобили, които ще оправдаят очакванията Ви! Не спираме да доказваме нашите качества като Ви предлагаме части и поддръжка в сервиз със специална отстъпка за всеки наш клиент. 
Нашата цел е постоянно развитие, предлагане на качествени автомобили, продукти и услуги за тях, винаги коректни и лоялни отношения към всички наши клиенти. При предварителна заявка за вас можем да подберем специално желания автомобил-цвят на автомобила, съчетан мотора скоростите и екстрите по Ваш избор. 
Бест Ауто-85 е компания с утвърдени традиции в закупуването и доставката на автомобили от Корея и Европа. Ние се различаваме от традиционните автокъщи и посредници в България, заради типа услуга, който предлагаме. Ние сме не просто доставчик, а ваш партньор при избора и закупуването на бъдещия ви автомобил.</h3>
    </div>
    <Footer />
  </div>
  )
}

export default AboutUsPage