import React from 'react'
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const WarrantyPage = () => {
  return (
    <div>
    <Navbar />
    <div className='w-screen mb-40 mt-20 h-full'>
    <h1 className='text-3xl mt-20 mb-10 font-bold text-center text-white'>Гаранция</h1>
    <h3 className='text-md lg:text-xl px-10 lg:my-20 mb-3 font-semibold text-center text-white'>ГАРАНЦИОННИ СРОКОВЕ И УСЛОВИЯ</h3>
    <h2 className='text-sm lg:text-md lg:text-xl px-5 lg:px-40 lg:my-20 font-semibold text-center text-white'>
 1. Основни:<br></br>
 1.1.„Продавача” предоставя гаранция за двигателя, предавателната кутия и предавките (скоростите) и горивната система на моторното превозно средство идентифицирано в точка 2, при условията и по реда предвидени в този документ “Гаранционни срокове и условия” (наричано по-долу „Гаранция”). Получателят на права по тази Гаранция е КЛИЕНТА посочен в точка 3 (по-долу спомената); ако настъпи промяна в собствеността, тази предоставена гаранция се прекратява.<br></br>
 1.2. „Продавача“ гарантира, че към момента на извършване на покупко-продажба на моторното превозно средство идентифицирано в точка 2 същото е с изправен двигател, предавателна кутия и предавки (скоростите) и горивна система. Двигателят, предавателната кутия и горивната система нямат скрити дефекти. <br></br>
 2. Идентифициране на Моторното превозно средство (наричано по-долу „Автомобил”) <br></br>
 3. Идентифициране на клиента (наричан по-долу „КЛИЕНТ”) <br></br>
 4. Идентифициране на продавача (наричан по-долу „ПРОДАВАЧ”) <br></br>
 5. Период или Километраж на покритие от Гаранцията<br></br>
 6. Обхват на Гаранционното покритие: <br></br>
Тази гаранция е предоставена от продавача и това Гаранционното покритие не е лимитирано до критични функционални проблеми в задвижващата система на автомобила и други, които могат да оставят автомобила при проблем на пътя. Когато гаранционният иск е одобрен, Продавача предоставя сервизен ремонт на автомобила, без такси за резервни части и труд.<br></br>
7. Гаранцията не покрива: Следните случаи, изрично, няма да бъдат покрити от тази Гаранция.<br></br>
7.1 Основна сервизна поддръжка и обичайните износвания и щети на употребяваните автомобили, особено, но не ограничени до следните случи:<br></br>
А) Периодична сервизна поддръжка смазване, смяна на масло, всички филтри, антифриз, охладителна течност, регулиране и баланс на колелата, смяна на гуми, почистване на горивната система, тунинг на двигателя, проверка и регулиране на спирачките.<br></br>
Б) Подмяна на консумативите: въздушен филтър , свещи, инжекторни дюзи, задвижващи ремъци, спирачни дискове, накладки, четки на алтернатор и стартер, гумени части, предпазители, крушки.<br></br>
В) Смяна или допълване на течности: трансмисионна течност, спирачна течност, грес, хидравлична течност, течност за сервоуправлението, охлаждащи течности за радиатора,антифриз.<br></br>
Г) Артикули засегнати от употреба , износване и излагане и др. : стъкла, пера на чистачките, гумени материали (като маркучи, колани, и уплътнителни ленти), мека тапицерия и интериора,волан, метализирани части, брони, акумулатор.<br></br>
Д) Ходовата част или части от колелата изложени на повреди поради лоши пътища или неравни терени: части на окачването (амортисьори, шарнири, тампони, биалетки, накрайници, кормилна рейка и т.н..), гуми, колела.<br></br>
 
7.2 Щети и проблеми, дължащи се на небрежност, неправилна употреба или грижа, престъпни деяния, или действие на околната среда, както например, но не ограничени до.<br></br>
А) Липса на подходящи грижи и поддръжка, както е указано от продавача.<br></br>
Б) Ненавременен сервиз поради забавяне на Клиента.<br></br>
В) Катастрофи, кражби или злоупотреба с Превозното средство.<br></br>
Г) Използване на автомобила в състезания, ралита и други състезателни събития или оф-роуд шофиране.<br></br>
Д) Използване на неправилни или мръсни горива, масла или течности.<br></br>
Е) Ползване на сервиз , който не е одобрен от Компанията.<br></br>
Ж) Изменения на превозното средство<br></br>
З) Индустриални , химически, или други въздушни отлагания, сол, цветен прашец, пясък, прах, чакъл, отломки на пътя, градушка, буря, светкавица и т.н.<br></br>
 
8. Отговорности на клиента: Всякакви проблеми или такива причинени от небрежност или непредпазливост на клиента, особено свързани с отговорностите на Клиента , така както са описани по-долу , няма да се покриват от Гаранцията.<br></br>
А)   Използване на превозното средство с нормални и добри практики на управление и грижа<br></br>
Б) Техническо обслужване и грижи в съответствие с инструкциите, предоставени от производителя.<br></br>
В) Специялно внимание и грижа за LPi двигател съгласно инструкциите предоставени от производителя.<br></br>
Г) Клиентът трябва да информира Продавача, преди превозното средство да измине пробег от 10 000км за бъде обслужен. Смяна на масло, маслен филтър, газови филтри. Разходи за смяната ще бъдат поети от Купувача.<br></br>
Д) Клиентът ще води автомобила в сервиз, който производителя посочи за подходящ за периодичен сервиз, инспекция и поддръжка.<br></br>
Е) Ако забележи сигнали за проблем (шум, миризма, дим, необичайна вибрация, ненормално функциониране или неизправност, и др) Клиента трябва да сигналицзира на производителя до 48 часа по телефона.  <br></br>
9. Процедури при Гаранционен Иск:<br></br>
А) Незабавно уведомяване по телефона:<br></br>
Б) Гаранционна Молба: Клиентът трябва да закара автомобила до сервиз посочен от Продавача и да подаде Гаранционна молба.<br></br>
10. Разни<br></br>
Финално решение по гаранционна жалба: Продавача си запазва правото да вземе крайното решение по гаранционната жалба.<br></br>
11. Форсмажорни обстоятелства: Производителят по никакъв начин не носи отговорност за неизпълнение, забавяне или недостиг при изпълнение на задълженията си по осигуряване на тази гаранция в резултат на стачки, войни , инциденти, бунтове, природни бедствия, ембарго, локаут, злополуки в транспорта, натовареността на пристанището, пожар, наводнение, буря, Божие дело, прекъсване на комуникации, забрана със закон или други причини извън неговия разумен контрол.
 </h2>
    </div>
    <Footer />
  </div>
  )
}

export default WarrantyPage